
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine, getTestPort } from "@/utils/common";
import {Loading, Pageniation, Table, TableRowsSelect} from "../../utils/decorator";
import dayjs from "dayjs";
import { formDownload } from "../../utils/tools";
import tableCheck from "@/mixins/table-check";
import t from "@common/src/i18n/t";
import StandardFlag from "@/components/scope/standard-flag.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";
import any = jasmine.any;
const packageInfo = namespace("packageInfo");
const app = namespace("app");

@Component({
  components: { TableEnableFlag, StandardFlag, SwitchStatus }
})

@Pageniation("loadListData")
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.choose-payment-method"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})

export default class packageInfoList extends Mixins(tableCheck) {

  @packageInfo.Action packageInfoPageQuery;
  @packageInfo.Action packageInfoSetStatus;
  @packageInfo.Action getAssignStore;
  @packageInfo.Action saveAssignStore;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;

  // dayjs = dayjs;
  searchForm = {
    packageCode: ''
  };
  packageList: any=[]
  // emptyToLine = emptyToLine;
  // storeList: any = [];
  // activeRow: any = {};
  // showForbidTips: boolean = false;
  // shwoResendModal: boolean = false;

  // get searchList() {
  //   return [
  //     {
  //       label: t("v210831.store-name"),
  //       type: "input",
  //       value: "",
  //       prop: "storeName"
  //     },
  //     {
  //       label: t("v210831.store-number"),
  //       type: "input",
  //       value: "",
  //       prop: "storeCode"
  //     },
  //     {
  //       label: t("setting.region"),
  //       type: "area",
  //       value: "",
  //       labels: [],
  //       prop: "area",
  //       anyLevel: true
  //     },
  //     {
  //       label: t("v210831.person-in-charge"),
  //       type: "input",
  //       value: "",
  //       prop: "userFirstName"
  //     },
  //     {
  //       label: t("v210831.store-mobile-person-in-charge"),
  //       type: "input",
  //       value: "",
  //       prop: "userMobile"
  //     },
  //     {
  //       label: t("v210831.application-source"),
  //       type: "select",
  //       selectList: this.storeSourceList,
  //       value: "",
  //       prop: "storeSource"
  //     },
  //     {
  //       label: t("v210831.store-type"),
  //       type: "select",
  //       value: "",
  //       selectList: this.storeTypeList,
  //       prop: "storeType"
  //     }
  //   ];
  // }

  handleSearch(data) {
    console.log(data);
    this.mix_pageNum = 1;

    this.loadListData();
  }
  created() {
    this.loadListData();
  }
  @Loading()
  loadListData() {
    return this.packageInfoPageQuery({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      console.log("storeList", data);
      this.packageList = data.data.list;
      console.log(data)
      return data;
    });
  }

  // handleImportStore() {
  //   (this.$refs.refUpload as any).handleOpen();
  // }
  // handleShowResendModal(row) {
  //   this.activeRow = row;
  //   this.shwoResendModal = true;
  // }

  handleView(row) {
    this.$router.push(`/management/store-view/${row.storeCode}`);
  }
  // handleResendPhoneMessage() {
  //   this.btnStartLoading();
  //   this.resendPhoneMessage({
  //     storeCode: this.activeRow.storeCode
  //   })
  //       .then(data => {
  //         this.$message.success(t("v210831.sent-successfully"));
  //         this.shwoResendModal = false;
  //       })
  //       .finally(() => {
  //         this.btnStopLoading();
  //       });
  // }
  // handleBatchStart() {
  //   if (this.mix_selectedRow.length < 1) {
  //     this.$message.warning(t("finance.please-select-supplier"));
  //   } else {
  //     this.confirmUpdateStatus(1);
  //   }
  // }
  // handleBatchForbid() {
  //   if (this.mix_selectedRow.length < 1) {
  //     this.$message.warning(t("finance.please-select-supplier"));
  //   } else {
  //     this.showForbidTips = true;
  //   }
  // }
  confirmUpdateStatus(status, selectedRow) {
    console.log(status)
    console.log(selectedRow)
    if (status) {
      this.packageInfoSetStatus({
        packageCode: selectedRow.packageCode, status: status
      }).then(data => {
        this.$message.success(t("setting.enable-successful"));
        // this.showForbidTips = false;
        this.loadListData();
      });
    } else {
      this.packageInfoSetStatus({
        packageCode: selectedRow.packageCode, status: status
      }).then(data => {
        this.$message.success(t("setting.disable-success"));
        // this.showForbidTips = false;
        this.loadListData();
      });
    }
  }

  assignDialogVisible=false;
  assignPackageCode='';
  packageAssignStoreData=[];
  selectPackageAssignStoreData = []
  handleAssign(row) {
    this.assignPackageCode = row.packageCode
    this.packageAssignStoreData = [];
    this.selectPackageAssignStoreData = [];
    this.getAssignStore({
      "packageCode": this.assignPackageCode
    }).then(data => {

      console.log("server data");
      console.log(data.data);

      let allStore = data.data.assignStores.concat(data.data.noAssignStores);
      allStore.forEach(item => {
        const obj = {"key": item.storeCode, "label": item.storeName}
        this.packageAssignStoreData.push(obj);
      });

      console.log("serviceAssignStoreData");
      console.log(this.packageAssignStoreData);

      data.data.assignStores.forEach(item => {
        this.selectPackageAssignStoreData.push(item.storeCode);
      });

      console.log("selectServiceAssignStoreData");
      console.log(this.selectPackageAssignStoreData);
    });
    this.assignDialogVisible = true;
  }

  handleSaveAssign() {
    this.btnStartLoading();
    this.saveAssignStore({
      "packageCode": this.assignPackageCode,
      "storeCodes": this.selectPackageAssignStoreData
    }).then(data => {
      this.$message.success(this.$t("base.success") as any);
      this.assignDialogVisible = false;
    }).finally(item => {
      this.btnStopLoading();
    });
  }
}
